/*----------------------------------------------------------------------------*\
  Map styles
\*----------------------------------------------------------------------------*/
body {
    max-height: 100%;
    margin: 0;
    padding: 0;
}

html {
    font-size: 16px;
}

img {
    max-width: 100%;
}

#map-canvas{
  width:100%;
  background: #770000;
}

.ui.bottom.attached.segment.pushable {
    margin: 0;
    border: 0;
    max-height: calc(100vh - 46px);
}

.header {
    text-align: center;
}

/*----------------------------------------------------------------------------*\
  Modal
\*----------------------------------------------------------------------------*/
@media only screen and (max-width: 767px) {
    .ui.modal > .close {
        z-index: 9999 !important;
        color: #000000 !important;
    }
}

.securityModal .actions {
    text-align:left !important;
}

/*----------------------------------------------------------------------------*\
  Side navigation
\*----------------------------------------------------------------------------*/
#side-menu {
    margin: 0;
    padding: 0;
    border: none;
    background-color: #464646;
}
.sideNav a > i.icon{
    float: right !important;
    cursor: pointer;
}

.sideNav a{
    padding:10px 30px 10px 30px;
    text-decoration:none;
    display:block;
    transition:0.3s;
    cursor: pointer;
    background-color: #464646;
    font-size: 1rem;
    color: #ffffff;
}

.sideNav .content a:hover{
    background-color: #4183c4;
    color: #fff;
    font-weight: bold !important;
}

#side-nav-menu-items {
    margin-bottom: 100px;
    margin-top: 1em;
}

#center-map-side,
#find-me-side,
#search-form-btn-side,
#poi-print-map {
    display: none;
}

#searchInstText {
    padding: 1rem;
    color: #fff;
}

/*----------------------------------------------------------------------------*\
  Side navigation and top menu common styles
\*----------------------------------------------------------------------------*/
#other-location-items, #search-form, #building-list {
    display: none;
}

#other-location-close-btn,
#building-list-close-btn,
#search-form-close-btn {
    background: #797979;
    color: #fff;
}

#find-me,
#print-map,
#locate-me-button-bottom,
#small-screen-search-icon,
#center-map,
#search-form-btn,
#center-map-bottom {
    color: #fff !important;
}

i.print.icon::before,
i.bicycle.icon::before,
i.tint.icon::before,
i.bolt.icon::before,
i.phone.icon::before,
i.universal.access.icon::before,
i.medkit.icon::before,
i.graduation.icon::before,
i.shield.icon::before,
i.world.icon::before,
i.university.icon::before,
i.book.icon::before,
i.music.icon::before,
i.paw.icon::before,
i.car.icon::before,
i.train.icon::before,
i.utensils.icon::before,
i.shopping.icon::before,
i.envelope.icon::before,
i.credit.icon::before,
i.home.icon::before,
i.home.icon::before,
i.flag.outline.icon::before,
i.building.icon.sidebarIcon::before,
i.tree.icon::before,
i.certificate.icon::before {
    background: #93958D !important;
    color: #464646 !important;
    font-size: 1.1em !important;
    padding: 7px;
    -moz-border-radius: 10%;
    -webkit-border-radius: 10%;
    border-radius: 10%;
    border: 2px solid #93958D;
}

i.download.icon.sidebarIcon::before,
i.redo.icon.sidebarIcon::before,
i.location.arrow.icon.sidebarIcon::before,
i.search.icon.sidebarIcon::before {
    background: #770000 !important;
    color: #fff !important;
    font-size: 1.1em !important;
    padding: 7px;
    -moz-border-radius: 10%;
    -webkit-border-radius: 10%;
    border-radius: 10%;
    border: 2px solid #770000;
}

i.binoculars.icon::before,
i.crosshairs.icon::before {
    color: #fff !important;
    font-size: 1.1em !important;
}

i.graduation.icon::before {
    padding-right: 3px;
}

i.shield.icon::before {
    padding-right: 7px;
}

i.building.icon.sidebarIcon::before,
i.book.icon::before {
    padding-right: 9px !important;
}

i.train.icon::before {
     padding-right: 8px !important;
     padding-left: 8px !important;
}

i.utensils.icon::before {
    padding-right: 11px;
}

i.card.icon::before,
i.shopping.icon::before,
i.home.icon::before {
    padding-right: 5px;
}

i.tree.icon::before {
    padding-right: 10px;
    padding-left: 10px;
}

/* Set hover color for all sidebar Items */
a.sideNavList:hover, a.otherLocationsItm:hover {
    background-color: #266fc5;
    color: #fff;
}

/* Set active background color for selected sidebar POI Items*/
a.active.sideNavList, a.active.otherLocationsItm {
    background-color: #266fc5;
    color: #fff;
}

.bldgActive {
    background-color: #266fc5 !important;
    color: #fff;
}

/* Set hover effect for all sidebar icons */
.otherLocationsItm:hover > i.sidebarIcon::before,
.otherLocationsItm:hover > i.map.marker.icon::before,
.otherLocationsItm:hover > i.flag.outline.icon::before,
.mainLocationSelect:hover i.poiIcons::before {
    background: #fff !important;
    color: #770000 !important;
    font-size: 1.1em !important;
    padding: 7px;
    -moz-border-radius: 10%;
    -webkit-border-radius: 10%;
    border-radius: 10%;
    border: 2px solid #fff;
}

/* Set active color for sidebar Icons */
.active.mainLocationSelect i.poiIcons::before {
    background: #fff !important;
    color: #770000 !important;
    font-size: 1.1em !important;
    padding: 7px;
    -moz-border-radius: 10%;
    -webkit-border-radius: 10%;
    border-radius: 10%;
    border: 2px solid #fff;
}

.active.mainLocationSelect i.tree.icon::before {
    padding-right: 9px;
    padding-left: 9px;
}

/* Set hover size for selected sidebar icons */
.mainLocationSelect:hover i.graduation.icon::before {
    padding-right: 3px;
}
.mainLocationSelect:hover i.shield.icon::before {
    padding-right: 7px;
}
.mainLocationSelect:hover i.utensils.icon::before {
    padding-right: 11px;
}
.mainLocationSelect:hover i.card.icon::before,
.mainLocationSelect:hover i.shopping.icon::before,
.mainLocationSelect:hover i.home.icon::before {
    padding-right: 5px;
}
.mainLocationSelect:hover i.tree.icon::before,
.otherLocationsItm:hover i.map.marker.icon::before {
    padding-right: 9px;
    padding-left: 9px;
}

/* Set active effect for all sidebar icons */
.active.mainLocationSelect i.graduation.icon::before {
    padding-right: 3px;
}
.active.mainLocationSelect i.shield.icon::before {
    padding-right: 7px;
}
.active.mainLocationSelect i.utensils.icon::before {
    padding-right: 11px;
}
.active.mainLocationSelect i.card.icon::before,
.active.mainLocationSelect i.shopping.icon::before,
.active.mainLocationSelect i.home.icon::before {
    padding-right: 5px;
}

/*----------------------------------------------------------------------------*\
  Map top menu bar
\*----------------------------------------------------------------------------*/
.ui.menu.navbar {
    background-color: #770000;
    border: none !important;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 0;
    box-shadow: none;
    height: 45px;
}

.ui.menu .item:before{
    background: #000;
    opacity: 0;
}

.stripe {
    background: #7f7f7f !important;
    position: relative;
    left: -15px;
    width: 10px;
    padding: 0 !important;
    transform: skew(-25deg, 0);
}

.blockStripe {
    background: #000 !important;
    position: relative;
    left: -10px;
    width: 20px;
    margin: 80 !important;
    transform: skew(-25deg, 0);
}

.ui.list.item.logo {
    position: relative;
    padding: 2px 5px;
    margin: 0;
    background-color: #000;
    width: 155px;
}

.mapTopMenu {
    color: #e8c60d !important;
    background-color: #000 !important;
    font-size: 1.2em;
    padding-right: 10px !important;
}

.headerImg {
    object-fit: cover;
    width:136px; 
    height:43px;
}

#center-map {
    padding-right: 0;
}

#find-me {
    padding-left: 0;
}

/*----------------------------------------------------------------------------*\
  InfoBox
\*----------------------------------------------------------------------------*/
.infoPhoto {
    float: left;
    padding-right: 10px;
}

#iw-bodyContent {
    margin: 5px 5px 0 5px  !important;
}

#content  .info-window-top {
   font-family: 'Raleway', sans-serif !important;
   padding: 10px;
   background-color: #770000;
   color: #fff;
   border-bottom: solid 3px;
   border-color: #e8c60d;
}

.gm-style-iw {
   width: 350px !important;
   top: 15px !important;
   left: 10 !important;
   background-color: #fff;
   box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
}

.iw-media-wrapper{
    content: "";
    display: block;
    clear: both;
}

.box {
    height: 40px;
    min-width: 30px;
    float: left
}

button.gm-ui-hover-effect {
    background-color: #fff !important;
    top: 20px !important;
    right: 40px !important;
    border-radius: 50%;
}

#getLocation {
    margin-left: 2px;
}

.iw-button {
    border-radius: 0 !important;
}

.infoPhoto {
    width: 100%;
    padding: 0;
    height: 186;
}

#picHolder {
    padding-bottom: calc(100%/186 * 100);
}

/*----------------------------------------------------------------------------*\
  Sidebar other locations
\*----------------------------------------------------------------------------*/
.map.marker.icon::before {
    font-size: 1.4em !important;
    padding: 5px 7px;
    -moz-border-radius: 10%;
    -webkit-border-radius: 10%;
    border-radius: 10%;
}

.otherLocalsText {
    padding: 0px 20px 0px 20px !important;
}

.map.marker.icon.one {
    color:  #41b3a3;
}
.map.marker.icon.two {
    color:  #e27d60;
}
.map.marker.icon.three {
    color:  #8d8741;
}
.map.marker.icon.four {
    color:  #659dbd;
}
.map.marker.icon.fivr {
    color:  #7e685a;
}
.map.marker.icon.six {
    color:  #4056a1;
}
.map.marker.icon.seven {
    color:  #ac3b61;
}
.map.marker.icon.eight {
    color:  #ff652f;
}

div.adp {
    padding: 0 10px 0 10px;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*----------------------------------------------------------------------------*\
  Sidebar Autocomplete
\*----------------------------------------------------------------------------*/
input#autocomplete {
    border-radius: 0%;
}

.autocomplete-suggestions {
    border-top: 0 !important;
    border: 1px solid rgba(34, 36, 38, 0.15);
    background: #E1E5E8;
    overflow: auto;
}

.autocomplete-suggestion {
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-selected {
    background: #fff;
    cursor: pointer;
}

.autocomplete-suggestions strong {
    font-weight: normal;
    color: #3399FF;
}

.autocomplete-group {
    padding: 2px 5px;
}

.autocomplete-group strong {
    display: block;
    border-bottom: 1px solid #000;
}

/*----------------------------------------------------------------------------*\
  Map Marker ICON Fonts
\*----------------------------------------------------------------------------*/
@font-face {
    font-family: 'Fontawesome';
    src: url("fonts/fa-solid-900.eot");
    src: url("fonts/fa-solid-900.woff") format("woff"), url("fonts/fa-solid-900.eot") format("truetype");
}

/*----------------------------------------------------------------------------*\
  HEADER AND TOP NAV   
\*----------------------------------------------------------------------------*/
.menuHighlight:hover {
    color: #e8c60d;
}

/*----------------------------------------------------------------------------*\
  MEDIA QUERIES   
\*----------------------------------------------------------------------------*/    
@media only screen and (min-width:1400px){
    .ui.grid.container,.ui.container{
        width:calc(1402px) !important;
    }
}

@media screen and (max-width:768px){
    .map-tools-bottom {
        font-size: .8em;
    }

    .ui.compact.menu {
        display: none;
    }

    #menu-caption {
        font-size: 1em
    }

    #side-nav-caption {
        font-size: 1em
    }

    .navbar {
        display:block;
    }

    div.ui.three.item.menu.small-screen-menu {
        display: flex;
    }

    .mapMenuTools {
        display: none !important;
    }

    .item.blockStripe {
        padding-left: 2px !important;
    }

    #center-map, #locate-me-button {
        font-size: .9em;
        padding-left: 0;
    }
    
    #center-map-side,
    #find-me-side,
    #search-form-btn-side,
    #poi-print-map {
        display: block;
    }
}

/*----------------------------------------------------------------------------*\
  Sidebar Accordion styles   
\*----------------------------------------------------------------------------*/
.accordion {
    width: 100%;
    margin: 0;
}

.accordion h2, li {
    cursor: pointer;
    margin: 0;
}

.accordion li {
    font-family: 'Roboto', sans-serif;
}

.accordion h2 {
    padding: 5px 20px;
    background-color: #1B1C1D;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
    color: #9D9D9D;
}

.accordion h2:hover {
    color: #FDFEFE;
}

.accordion h2:not(:last-of-type) {
    border-bottom: 1px solid #424949;
}

.accordion div,
.accordion p {
    display: none;
}

.accordion li {
    padding: 5px 25px;
    background-color: #464646;
    font-size: 1rem;
    color: #ffffff;
}

.accordion li:hover {
    background-color: #266fc5;
}

.accordion li:not(:last-of-type) {
    border-bottom: 1px solid #333333;
}

.accordion .open_accordion {
    display: block;
}

p, .open_accordion {
    margin: 0;
}

.buildList{
    padding: 0;
    margin: 0;
}

.savingSmall {
    display: none;
    color: #fff;
}

/*By http://martinwolf.org/2015/01/08/pure-css-savingloading-dots-animation/*/
.saving {
    display: none;
    color: #fff;
}
@keyframes blink {
    0% {
      opacity: .2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .2;
    }
}

.saving span, .savingSmall span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.saving span:nth-child(2), .savingSmall span:nth-child(2) {
    animation-delay: .2s;
}

.saving span:nth-child(3), .saving span:nth-child(3) {
    animation-delay: .4s;
}